import logo from './logo.svg';
import './App.css';
import sdata from './data/Data.json';
import chipsData from './data/Chips.json';
import { useEffect, useState } from 'react';

function App() {
  const [searchValue, setSearchValue] = useState('');
  const [articles, setArticles] = useState([]);
  const [expandedIndex, setExpandedIndex] = useState(null);
  const [selectedChip, setSelectedChip] = useState(null);

  const handleSearchValue = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchValue(value);

    const filteredArticles = sdata.filter(item =>
      item.keywords?.some(keyword => keyword.toLowerCase().includes(value))
    );
    setArticles(filteredArticles);
  };

  const handleChip = (chipName) => {
    const filteredArticles = sdata.filter(item =>
      item.keywords?.some(keyword => keyword.toLowerCase().includes(chipName.toLowerCase()))
    );
    setArticles(filteredArticles);
    setSelectedChip(chipName);
  };

  useEffect(() => {
    setArticles(sdata);
  }, []);

  const toggleExpand = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  return (
    <>
      <header className='my-3 sticky-top bg-white'>
        <div className='logo d-flex justify-content-center'>
          {/* <h1></h1> */}
        </div>

        <div className='sticky-top d-flex justify-content-center'>
          <div className="d-flex w-75 flex-column align-items-center justify-content-center " role="search">
            <div className='searchbox-container w-75'>
              <i className="fa-solid fa-magnifying-glass p-1"></i>
              <input
                className="searchbox me-2"
                onChange={handleSearchValue}
                value={searchValue}
                type="search"
                placeholder="Search"
                aria-label="Search"
              />
            </div>

            <div className='pt-3'>
              <ul className='d-flex justify-content-around list-unstyled flex-wrap'>
                {chipsData.map((chip, index) => (
                  <li key={index}>
                    <div
                      onClick={() => handleChip(chip.name)}
                      className={`chip m-2 px-3 py-1 d-flex justify-content-around align-items-center border border-light-subtle rounded-pill ${selectedChip === chip.name ? 'chip-active ' : ''}`}
                    >
                      <i className={`fa-brands ${chip.icon} ${chip.colorClass}`}></i>
                      <span className='mx-1'>{chip.name}</span>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <hr />
      </header>

      <section>
        <div className='result-container'>
          {articles.map((article, index) => (
            <div className="card mb-3" key={index}>
              <div className="card-body">
                <h5 className="card-title">{article.name}</h5>
                <div className="card-text">
                  <p className=".text-dark" dangerouslySetInnerHTML={{ __html: article?.shortdescription }}></p>
                  <div dangerouslySetInnerHTML={{ __html: article?.description }} />
                </div>
              </div>
              <div className="card-footer text-muted">
                {/* Published on: {article.readable_publish_date} */}
              </div>
            </div>
          ))}
        </div>
      </section>
    </>
  );
}

export default App;
